import React, { useEffect } from 'react'
import { Portal } from '../../utils/Portal/Portal'
import s from './Privacy.module.css'

export const Privacy = ({ setPrivacy }) => {
  const escConsultForm = e => {
    if (e.key === 'Escape') {
      setPrivacy(false)
    }
  }

  useEffect(() => {
    document.addEventListener('keydown', e => escConsultForm(e))
    return document.removeEventListener('keydown', e => escConsultForm(e))
  }, [])

  return (
    <Portal>
      <div className={s.privacy}>
        <p className={s.privacy__esc}>Для выхода нажмите ESC</p>
        <div className={s.privacy__info}>
          <h2>Соглашение о защите персональных данных пользователя</h2>
          <p className={s.privacy__subtitle}>Политика конфиденциальности</p>
          <p className={s.privacy__item}>
            Настоящая Политика конфиденциальности определяет политику Frontier,
            расположенной по адресу: г. Санкт-Петербург, Невский пр-кт, 71 (5
            этаж) (далее – Администрация Сайта), в отношении обработки
            персональных данных и содержит сведения о реализуемых требованиях к
            защите персональных данных физических лиц, пользующихся услугами
            интернет-сайта http://frontiers.ru/ (далее – Сайт) и его сервисов
            (далее – Пользователи).
          </p>
          <p className={s.privacy__subtitle}>Основные понятия</p>
          <p className={s.privacy__item}>
            Персональные данные - любая информация, относящаяся к прямо или
            косвенно определенному или определяемому физическому лицу; обработка
            персональных данных - любое действие (операция) или совокупность
            действий (операций), совершаемых с использованием средств
            автоматизации или без использования таких средств с персональными
            данными, включая сбор, запись, систематизацию, накопление, хранение,
            уточнение (обновление, изменение), извлечение, использование,
            передачу (распространение, предоставление, доступ), обезличивание,
            блокирование, удаление,уничтожение персональных данных.
          </p>
          <p className={s.privacy__subtitle}>
            Предоставление персональных данных
          </p>
          <p className={s.privacy__item}>
            Предоставляя нам свои персональные данные при использовании Сайта,
            Вы даете согласие на обработку Ваших персональных данных в
            соответствии с данной Политикой конфиденциальности.
          </p>
          <p className={s.privacy__item}>
            В частности, дачей согласия на обработку Ваших персональных данных
            является:
          </p>
          <p className={s.privacy__item}>
            · регистрация на Сайте посредством заполнения регистрационной формы
            с указанием необходимой и, при желании, дополнительной информации,
            предусмотренной формой;
          </p>
          <p className={s.privacy__item}>
            · подписка на Сайте на рассылку новостных и информационных
            материалов посредством заполнения формы с указанием необходимой
            информации, предусмотренной формой;
          </p>
          <p className={s.privacy__item}>
            · оформление на Сайте заявки на получение услуг с указанием
            необходимой информации, предусмотренной формой заявки;
          </p>
          <p className={s.privacy__item}>
            · предоставление персональных данных посредством заполнения иных
            информационных полей на Сайте (при наличии);
          </p>
          <p className={s.privacy__item}>
            · предоставление персональных данных путем отправки электронных
            сообщений в адрес Администрации Сайта.
          </p>
          <p className={s.privacy__item}>
            Подписка на Сайте на рассылку новостных и информационных материалов
            посредством заполнения соответствующей формы является согласием
            Пользователя на получение от Администрации Сайта сообщений
            рекламно-информационного характера. Если Пользователь не желает
            получать рассылки от Администрации Сайта, он вправе отписаться от
            рассылки Администрации Сайта.
          </p>
          <p className={s.privacy__item}>
            Администрация Сайта обязуется не раскрывать третьим лицам и не
            распространять персональные данные без согласия Пользователя, если
            иное не предусмотрено федеральным законом.
          </p>
          <p className={s.privacy__item}>
            Пользователь вправе отозвать согласие на обработку своих
            персональных данных, предоставленных Администрации Сайта. Для этого
            необходимо отправить соответствующее уведомление на адрес:
            nk@frontiers.ru
          </p>
          <p className={s.privacy__subtitle}>
            Цели сбора и обработки персональных данных
          </p>
          <p className={s.privacy__item}>
            Администрация Сайта обрабатывает Ваши персональные данные в целях,
            для которых они были предоставлены, в том числе:
          </p>
          <p className={s.privacy__item}>
            · для регистрации запроса Пользователя на Сайте;· для регистрации
            Пользователя на Сайте для предоставления расширенного доступа к
            функционалу Сайта;
          </p>
          <p className={s.privacy__item}>
            · для направления Пользователю наших новостных и (или)
            информационных материалов, в т.ч. информации об Администрации Сайта,
            наших услугах, мероприятиях;
          </p>
          <p className={s.privacy__item}>
            · для ответа на вопросы и иные обращения Пользователей;
          </p>
          <p className={s.privacy__item}>
            · для рассмотрения запроса о предоставлении услуг Пользователю;
          </p>
          <p className={s.privacy__item}>
            · для выполнения обязанностей, установленных для Администрации Сайта
            законодательством Российской Федерации;
          </p>
          <p className={s.privacy__item}>
            · для других целей с согласия Пользователя.
          </p>
          <p className={s.privacy__subtitle}>Защита персональных данных</p>
          <p className={s.privacy__item}>
            Администрация Сайта принимает необходимые правовые, организационные
            и технические меры для защиты персональных данных Пользователей от
            неправомерного или случайного доступа к ним, уничтожения, изменения,
            блокирования, копирования, предоставления, распространения
            персональных данных, а также от иных неправомерных действий в
            отношении персональных данных.
          </p>
          <p className={s.privacy__item}>
            Администрация Сайта ограничивает внутренний доступ к персональным
            данным Пользователей исключительно сотрудниками, которым это
            необходимо для выполнения своих служебных обязанностей, с
            предварительным ознакомлением таких сотрудников с настоящей
            Политикой конфиденциальности, а также нормативно-методическими
            документами по защите информации.
          </p>
          <p className={s.privacy__item}>
            При работе с персональными данными исключается бесконтрольное
            использование защищаемой информации.
          </p>
          <p className={s.privacy__item}>
            Внешний доступ к персональным данным Пользователей ограничивается
            исключительно лицами (органами) и случаями, предусмотренными
            законодательством Российской Федерации.
          </p>
          <p className={s.privacy__item}>
            Администрация Сайта принимает локальные акты по вопросам
            безопасности персональных данных и назначает лицо, ответственное за
            организацию обработки персональных данных.
          </p>
          <p className={s.privacy__item}>
            Администрация Сайта определяет угрозы безопасности персональных
            данных при их обработке в информационных системах персональных
            данных, учитывает и обеспечивает сохранность машинных и иных
            носителей персональных данных, применяет прошедшие в установленном
            порядке процедуру оценки соответствия средства защиты информации,
            обеспечивает контроль за действиями, совершаемыми с персональными
            данными в информационной системе персональных данных.
          </p>
          <p className={s.privacy__subtitle}>Хранение персональных данных</p>
          <p className={s.privacy__item}>
            Хранение персональных данных Пользователей осуществляется
            Администрацией Сайта в форме, позволяющей определить субъекта
            персональных данных, не дольше, чем этого требуют цели обработки
            персональных данных, если иной срок хранения персональных данных не
            установлен федеральным законом, договором, стороной которого,
            выгодоприобретателем или поручителем по которому является
            Пользователь, либо Пользователем при даче согласия на обработку
            персональных данных. Обрабатываемые персональные данные подлежат
            уничтожению либо обезличиванию по достижении целей обработки или в
            случае утраты необходимости в достижении этих целей, если иное не
            предусмотрено федеральным законом.
          </p>
          <p className={s.privacy__subtitle}>
            Прекращение обработки персональных данных
          </p>
          <p className={s.privacy__item}>
            Администрация Сайта прекращает обработку и уничтожает персональные
            данные в случае достижения цели обработки персональных данных или
            отзыва Пользователем согласия на обработку его персональных данных,
            если иное не предусмотрено договором, стороной которого,
            выгодоприобретателем или поручителем по которому является
            Пользователь, иным соглашением между Администрацией Сайта и
            Пользователем.
          </p>
          <p className={s.privacy__item}>
            Администрация Сайта прекращает обработку персональных данных также
            при наступлении условий прекращения обработки персональных данных; в
            случае выявления неправомерной обработки персональных данных, если
            обеспечить правомерность обработки невозможно; по истечении срока
            действия согласия Пользователя на обработку персональных данных; в
            случае ликвидации Администрации Сайта; в иных случаях,
            предусмотренных законом.
          </p>
          <button
            className={s.privacy__btn}
            type="button"
            onClick={() => setPrivacy(false)}
          >
            Закрыть
          </button>
        </div>
      </div>
    </Portal>
  )
}
