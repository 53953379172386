import React from 'react'
import s from './Alert.module.css'

export const Alert = ({ warn }) => {
  return (
    <div className={warn ? s.warn : s.success}>
      {warn ? (
        <p className={s.alert__text}>Упс! Что-то пошло не так...</p>
      ) : (
        <p className={s.alert__text}>Сообщение успешно доставлено</p>
      )}
    </div>
  )
}
