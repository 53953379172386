import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { Loader } from '../../ui/Loader/Loader'
import { Alert } from '../../ui/Alert/Alert'
import { REQUEST } from '../../api'
import logo from './img/logo-frontiers.svg'
import s from './Feedback.module.css'
import { Privacy } from '../Privacy/Privacy'

export const Feedback = () => {
  const [email, setEmail] = useState('')
  const [message, setMessage] = useState('')
  const [loading, setLoading] = useState(false)
  const [alert, setAlert] = useState(false)
  const [warn, setWarn] = useState('')
  const [privacy, setPrivacy] = useState(false)

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    mode: 'onBlur',
    defaultValues: { email: '', message: '' },
  })

  const emailChange = e => {
    setEmail(e.target.value.trimStart())
  }

  const messageChange = e => {
    setMessage(e.target.value.trimStart())
  }

  const onSubmit = async data => {
    setLoading(true)
    try {
      const response = await fetch(`${REQUEST}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      })
      const res = await response.json()
      setAlert(true)
    } catch (error) {
      console.log(error)
      setLoading(false)
      setAlert(true)
      setWarn('warn')
    }
    setEmail('')
    setMessage('')
    setLoading(false)
    setTimeout(() => setAlert(false), 3000)
    reset()
  }

  console.log(loading)
  return (
    <div className={s.container}>
      <div className={s.logo}>
        <img
          src={logo}
          alt="logo"
          width="180"
        />
      </div>
      <div className={s.feedback}>
        <h1 className={s.title}>Служба поддержки</h1>
        <form
          className={s.support}
          onSubmit={handleSubmit(onSubmit)}
        >
          <div>
            <input
              className={s.feedback__mail}
              id="email"
              name="email"
              {...register('email', {
                required: 'Обязательное поле',
                minLength: {
                  value: 6,
                  message: 'Минимум 6 символов',
                },
                maxLength: {
                  value: 50,
                  message: 'Максимальное количество символов не более 50',
                },
                pattern: {
                  value: /\S+@\S+\.\S+/,
                  message: 'Неверный формат email',
                },
              })}
              type="email"
              placeholder="example@mail.com"
              maxLength="50"
              onChange={emailChange}
              value={email}
              style={
                errors.email
                  ? {
                      outline: '1px solid #EA6342',
                      background: 'rgba(234, 99, 66, 0.1)',
                      border: 0,
                    }
                  : { outline: 'none' }
              }
            />
            <>
              {errors.email && (
                <p className={s.error}>{errors.email.message || 'Error'}</p>
              )}
            </>
          </div>
          <div>
            <textarea
              className={s.feedback__message}
              id="message"
              name="message"
              {...register('message', {
                required: 'Обязательное поле',
                minLength: {
                  value: 6,
                  message: 'Минимум 6 символов',
                },
                maxLength: {
                  value: 500,
                  message: 'Максимальное колтчество символов не более 500',
                },
              })}
              placeholder="Оставьте сообщение"
              maxLength="500"
              cols={31}
              onChange={messageChange}
              value={message}
              style={
                errors.message
                  ? {
                      outline: '1px solid #EA6342',
                      background: 'rgba(234, 99, 66, 0.1)',
                      border: 0,
                    }
                  : { outline: 'none' }
              }
            />
            <>
              {errors.message && (
                <p className={s.error}>{errors.message.message || 'Error'}</p>
              )}
            </>
          </div>
          <button
            className={s.feedback__btn}
            type="submit"
          >
            {loading ? <Loader /> : 'Отправить'}
          </button>
          {alert && <Alert warn={warn === 'warn' ? warn : null} />}
        </form>
        <div className={s.feedback__info}>
          <button
            className={s.info__btn}
            type="button"
            onClick={() => setPrivacy(true)}
          >
            Политика конфиденциальности
          </button>
        </div>
      </div>
      {privacy && <Privacy setPrivacy={setPrivacy}/>}
    </div>
  )
}
