import { Feedback } from './pages/Feedback/Feedback'
import './App.css'

function App() {
  return (
    <Feedback />
  )
}

export default App
